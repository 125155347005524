@import "https://use.fontawesome.com/ede1f9233a.css";
@import "variables";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,600italic);



/*Custom styling*/
main{
  .column, .columns{
    padding-left: 0;
    padding-right: 0;
  }
}

.homepage-block {
  .content {

  }
}
header{
  .logo{
    position: relative;
    top: -47px;
  }
  .lvl1 li a:after{
    background: #fff none repeat scroll 0 0;
    content: "";
    display: inline-block;
    height: 14px;
    margin: 0 3px 0 15px;
    width: 1px;
  }
}
.footer.variant1 .top p a{
  text-decoration: none !important;
}
.form.variant1 table td.formbuilder_label{
  font-size: 0.875rem;
}

.top-bar .title-area{
  height: 104px;
}
.accordion .accordion-navigation > a, .accordion dd > a{
  font-size: rem-calc(14);
}
.afbeelding{
  &.links{
    margin: 0 10px 2px 0;
  }
  &.rechts{
    margin: 0 0 2px 10px;
  }
}
/*Homepage Styling */
$slider-background:#231f20;
$light-grey: #eeeeee;
body.homepage{background: #f0ebdd;}
.secondary-bg{background: $secondary-color !important;}

.homepage main {padding-top:102px !important;}
.homepage #CCIPlaceHolder_Before_Content {z-index:9999;}
.homepage .slider.slick-initialized.slick-slider {z-index:0}
.homepage #CCIPlaceHolder_Before_Subnav .row {height:50px;}
.header.variant3.custom {
  .topMenu a{
    color:#ffffff;
  }
  .topServiceMenu{

    padding: 15px 0 0 0;
  }
  .topServiceMenu a{
    display: block;
    float: left;
    height: 39px;
    width: 39px;
    &#zoeken{
      background: url("/img/4/search-icon.gif")
    }
    &#home{
      background: url("/img/4/home-icon.gif")
    }
    &#contact{
      background: url("/img/4/contact-icon.gif")
    }
  }
  .bottomWrapper {background:white !important;
    .left {float:left; position:absolute;}
    .row {height:50px;}
  }
  .logo img{
    position: relative;
    top: -28px;
  }
  .topWrapper{
    background: $primary-color !important;
    border: none !important;
    height:rem-calc(60);
  }
  .counter{
    margin: rem-calc(8) 0 0 rem-calc(167);
    position: absolute;
    width: 230px;
    .amount{
      background: #ffffff none repeat scroll 0 0;
      float: left;
      font-size: 12px;
      letter-spacing: 5px;
      margin: 0 10px 0 0;
      padding: 4px;
      width: 54px;
    }
    span{
      display: block;
      float: left;
      width: 165px;
    }
  }

  .bottomWrapper .row{
    background-image:
            url(/img/4/menu-bg-l.gif),
            url(/img/4/menu-bg-r.gif);
    background-position:left top, right top;
    background-repeat: no-repeat, no-repeat;
    .column{
      padding: 0 rem-calc(49) 0 rem-calc(49);
    }
    .bottom{
      background: none;
      border: none;
    }
    .top-bar{
      position: static;
      background: url(/img/4/menu-bg.gif) repeat-x left 0px;
      height:50px !important;
      .top-bar-section{
        padding: rem-calc(10) rem-calc(16);
        margin:0 0 0 125px;
        ul li{
          background: none;
        }
        ul li:last-child > a{
          &:after{
            display: none;
          }
        }
        ul li > a{
          line-height: inherit;
          color:#ffffff;
          font-size: 16px;
          padding: 0 5px 5px !important;
          &:after{
            background: #fff none repeat scroll 0 0;
            content: "";
            display: inline-block;
            height: 14px;
            margin: 0 3px 0 15px;
            width: 1px;

          }
        }
      }
    }
  }

}

#hamburger{
  color: #473b1f;
  font-size: 32px;
  line-height: 0;
  padding: 4px 0 0 15px;
  margin:0 0 0 15px;
}

.homepage {
  .block.form.variant1{
    overflow: hidden;
    .formbuilder_label{
      font-size: rem-calc(14);
    }
  }
  .block.text.variant1 .btn a{
    border: 1px solid $primary-color;
    color: $primary-color;
  }
  #Block_Social_CCIPlaceHolder_Block_Twitter_Variant_1{padding: 0;}
  .banner {
    margin-bottom:2em;
    margin-top: em-calc(40);
    img {
      height: auto;
      width:100%;
    }
  }
  figure {
    margin:0;
  }
  main .row {
    background:none;
  }
  .btn a {
    border-color:$secondary-color;
    color:$secondary-color;
  }
  .sideColumn section{
    p{margin:0;}
    padding: 0 0 10px 0;
    .block{
      padding: 0.5rem 0.625rem 0.625rem 0.625rem;
      background: #ffffff;
      article a{
        color:$black;
      }
    }
    .block.pagesSmall{
      background:#faf7f2;
      a {
        font-size:14px;
      }
      .readMore{
        font-size: 0.75rem;
        color:$primary-color;
      }
    }
    .block.banner{
      padding:0;
      margin:0;
    }
    .pagesSmall article{
      padding: 0;
      margin: 0;
      border: none;
    }
    .pagesSmall .date-article{
      font-size: 11px;
      padding: 0 5px 0 0;
    }
    h3, h3 a{
      font-size: 1.5rem !important;
    }
  }

  .header-intro .btn{
    margin:0 0 em-calc(42) 0;
    display: block;
  }
  .header-intro .btn a{
    font-size: 1.5rem;
  }
  .top-bar-section ul {
    font-size:em-calc(20);
  }
}

.homepage-block {
  margin-bottom:1em;
  overflow:hidden;
  padding:0;

  .columns {
    height: 100%;
  }
  .content h1  {
    margin-top:60px;
  }

}

.homepage main .main-content{
  padding: 0 rem-calc(50);
  .columns{
    padding: 0;
    &:first-child{
      padding: 0 rem-calc(10) 0 0;
    }
  }
  .content{
    h1,h2,h3,h4,h5,h6{
      padding: 0 rem-calc(10);
    }
    padding: rem-calc(8) 0 0 0;
  }
}
section {
  &.homepage-block {
    img {
      height: auto;
      //max-height: 11em;
      //width:100%;
    }
    .btn {
      bottom:3em;
      color:$secondary-color;
      left:0;
      position: absolute;
      right: 0;
      text-align:center;
      a {
        border:0;
        color:$secondary-color;
        float:none;
      }
    }
    .content {
      background: #fff;
      float:left;
      padding:0 0 3em 0;
      h2,
      h3,
      p,
      .articlesNotFound {
        padding:0 .625rem;
      }
    }
  }
  .datelist {
    float:left;
    list-style:none;
    margin:0 0 4em 0;
    padding:0;
    width:100%;
    li {
      border-bottom:1px solid #f3f4f4;
      float:left;
      padding:.2em 0 .2em 5.5em;
      position:relative;
      width:100%;
    }
    time {
      color:$secondary-color;
      left:.5em;
      position: absolute;
    }
    h4 {
      color:$main-color;
      font-size:1em;
      font-weight: 700;
      margin:.1em 0 0 0;
      padding:0 .5em 0 0;
      a {
        color:inherit;
      }
    }
    .locatie {
      color:$secondary-color;
      font-weight:700;
    }
  }
}
.fullwidth-image {
  height:100%;
  margin:0 0 2em 0;
  padding:0;
  width:100%;
  img {
    height: auto;
    width:100%;
  }
}
.js-effect-scrollin {
  opacity:0;
  transform:translate3d(0px, 125px, 0px);
  //transition:opacity .5s cubic-bezier(0.4, .85, .49, .99) .4s, transform .5s cubic-bezier(0.4, .85, .49, .99) .4s;
  transition:all .5s;
  &.seen {
    opacity: 1;
    transform:translate3d(0px, 0, 0px);
  }
}

/*#banner-sliders{margin-top: em-calc(35);}*/
// SLIDER
.homepage {
  .afbeelding{
    &.links{
      margin: 0 10px 2px 0;
      padding: 0 0 0 10px;
    }
    &.rechts{
      margin: 0 0 2px 10px;
      padding: 0 10px 0 0;
    }
  }
  .header-intro h1{font-size: rem-calc(30); margin:1.875rem 0 2.625rem 0 !important;}
  .header-intro p{line-height: 20px;}
  #CCIPlaceHolder_Header {
    background-color:$primary-color;
  }
  .slider {
    background-color:$slider-background;
    .content {
      img {
        height: auto;
        width:100%;
      }
    }
  }
  .slider.variant1 .slide{
    padding:3.0rem 0 13.125rem 0;
  }
  .fullWidth .slider.variant1 .slide {
    max-height: 550px !important;
  }
  .variant1.slider .slick-dots {
    background-color:$primary-color;
    bottom:0;
    height:4em;
  }
  .variant1.slider .slide .caption {
    background-color:#fff;
  }
  .slider.variant1 .slick-dots li{
    top: 14px;
  }
  .fullWidth .variant1.slider .slide .caption {
    border-radius:.75em;
    margin-left:3em;
    width:50%;
  }
  .page-header{
    background: #ffffff;
    #overlay{
      background: -moz-linear-gradient(left, rgba(255,255,255,1) 3%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 82%, rgba(255,255,255,1) 92%);
      background: -webkit-gradient(left top, right top, color-stop(3%, rgba(255,255,255,1)), color-stop(20%, rgba(255,255,255,0)), color-stop(82%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
      background: -webkit-linear-gradient(left, rgba(255,255,255,1) 3%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 82%, rgba(255,255,255,1) 100%);
      background: -o-linear-gradient(left, rgba(255,255,255,1) 3%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 82%, rgba(255,255,255,1) 92%);
      background: -ms-linear-gradient(left, rgba(255,255,255,1) 3%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 82%, rgba(255,255,255,1) 92%);
      background: linear-gradient(to right, rgba(255,255,255,1) 3%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 82%, rgba(255,255,255,1) 92%);
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
  .page-header .caption p {
    color:$primary-color;
  }
  .secondary-navigation{
    background: #ffffff;
    .row .content{
      background-image: url(/img/4/menu-secondary-bg-l.gif),url(/img/4/menu-secondary-bg-r.gif);
      background-position:left top, right top;
      background-repeat: no-repeat, no-repeat;
      background-color: #f0ebdd;
    }
    ul{
      height: 50px;
      margin: 0;
      padding:0 50px;
    }
    li{
      list-style: outside none none;
      text-align: center;
      a{
        color: $black;
        font-size: rem-calc(18)
      }
      &:first-child{
        border-top: 15px solid $primary-color;
      }
      &:nth-child(2){
        border-top: 15px solid #c9af72;
      }
      &:nth-child(3){
        border-top: 15px solid #e9dec5;
      }
      &:nth-child(4){
        border-top: 15px solid #f4efde;
      }
      &:nth-child(5){
        border-top: 15px solid #d3bf8e;
      }
      &:last-child{
        border-top: 15px solid #dfcfab;
      }
    }
  }
  .slider.variant1 .slide .caption{
    h3, a{
      color:$secondary-color;
      line-height: 1.22;
      font-size: 1.2em;
    }

  }
  .top-bar.expanded .title-area{background: none;}
  .header.variant2 .top-bar .toggle-topbar a span::after {
    box-shadow: 0 0 0 1px $primary-color, 0 8px 0 1px $primary-color, 0 16px 0 1px $primary-color;
  }

  .header.variant3 .topBar, .header.variant3 .topBar a{
    color:#ffffff;
  }
  .variant3.custom .toggle-topbar.menu-icon a span::after {
    box-shadow: 0 0 0 2px #5b4d2a, 0 8px 0 2px #5b4d2a, 0 16px 0 2px #5b4d2a;
  }
  .variant3.custom .toggle-topbar.menu-icon a span::after {
    box-shadow: 0 0 0 1px #2f2f2f, 0 7px 0 1px #2f2f2f, 0 14px 0 1px #2f2f2f;
    content: "";
    display: block;
    height: 0;
    margin-top: -8px;
    position: absolute;
    right: 0.9375rem;
    top: 50%;
    width: 16px;
  }

  #banner-text-blocks .columns {
    padding:1.5625rem !important}
}
.homepage footer section{
  &.columns{
    padding-top:rem-calc(20);
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  padding: 1.25rem !important;
  &:first-child{
    padding-left: rem-calc(60) !important;
  }

}

@media #{$small-only} {

  h1{
    font-size: rem-calc(20);
  }
  .homepage main .main-content{
    h2{font-size: inherit}
  }
  .homepage .secondary-navigation{
    .columns{
      padding: 0;
    }
    main .row {
      background:none;
    }
    .row .content
    {
      background: none;
    }
    ul{
      margin: 0;
      background: #ffffff;
      padding: 0;
      height: 100%;
      li a{
        font-size: rem-calc(20);
      }
    }
  }

  .homepage {
    main .main-content{
      padding: 0;
    }
    main .main-content .columns:first-child{
      padding: 0;
    }
    .homepage-block{
      margin: 50px 0 0 0;
      .content h1 {
        margin-top:0px;
      }
    }
    .secondary-navigation li{
      padding-top: 12px;
      padding-bottom:12px;
    }
  }
  .header.variant3.custom{
    .counter{
      margin: rem-calc(15) 0 0 rem-calc(127);
      width: 174px;
      .amount{
        background: #ffffff none repeat scroll 0 0;
        float: left;
        font-size: 11px;
        letter-spacing: 5px;
        margin: 0 10px 0 0;
        padding: 3px;
        width: 46px;
      }
      span{
        display: block;
        float: left;
        font-size: 11px;
        font-weight: bold;
        line-height: 13px;
        width: 118px;
      }

    }
  }
  .header.variant3.custom .bottomWrapper .row{
    background: $primary-color;
  }
  .header.variant3.custom .bottomWrapper .row .bottom{
    padding: 0 rem-calc(16);
  }
  .row.slider .columns {
    padding-left: 0;
    padding-right: 0;
    .slide.slick-slide{
      width: 100% !important;
    }
  }
  .header.variant3 .top-bar .toggle-topbar.menu-icon{
    margin-top: 0 !important;
  }
  .homepage footer section{

    &:first-child{
      padding-left: 1.25rem !important;
    }

  }

}
@media #{$medium-only} {
  .homepage main .row {
    background:none;
  }
  .homepage {
    main .main-content .columns:first-child {
      padding: 0;
    }
  }
  .header.variant3 .top-bar .toggle-topbar.menu-icon{
    margin-top: 0 !important;
  }
}
